import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './components/Home.jsx';
import AboutUs from "./components/AboutUs.jsx";
import Service from "./components/Service.jsx";
import Product from "./components/Product.jsx";
import ContactUs from "./components/ContactUs.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/service" element={<Service />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
