import React from "react";
import Navbar from "./Navbar.jsx";
import "../styles/style.css";
import profile2 from "../assets/Swati1.jpg";
import profile3 from "../assets/aniket.jpg";
import profile1 from "../assets/profile-man.png";
import profile4 from "../assets/profile-women.png";

const TeamMemberCard = ({ name, position, imageUrl }) => {
  return (
    <>
      <div
        style={{ backgroundColor: "#070F2B", color: "#f0f0f0" }}
        className="overflow-hidden p-4"
      >
        <div className="flex justify-center mt-2">
          <div className="rounded-full border-4 p-1 gradient-border">
            <img
              className="w-24 h-24 object-cover "
              src={imageUrl}
              alt={`${name}'s profile`}
            />
          </div>
        </div>
        <div
          style={{ backgroundColor: "#1B1A55", color: "#f0f0f0" }}
          className="text-center p-2 mt-2 shadow-lg rounded-lg"
        >
          <h2 className="text-lg font-semibold">{name}</h2>
          <p className="text-sm text-gray-400">{position}</p>
        </div>
      </div>
    </>
  );
};

const AboutUs = () => {
  const teamMembers = [
    {
      name: "Mr P Ghosh",
      position: "Founder & CEO",
      imageUrl: profile1,
    },
    {
      name: "Mrs Tamalika Ghosh",
      position: "Executive Director",
      imageUrl: profile4,
    },
    {
      name: "Mrs Ruma Ghosh",
      position: "Director",
      imageUrl: profile4,
    },
    {
      name: "Aniket Mondal",
      position: "Associate Frontend Developer",
      imageUrl: profile3,
    },
    {
      name: "Swati Mandal",
      position: "Associate Frontend Developer",
      imageUrl: profile2,
    },
  ];

  return (
    <>
      <Navbar />
      <div
        style={{ backgroundColor: "#070F2B", color: "#f0f0f0" }}
        className="relative z-10 p-8 md:p-12 lg:p-16 mb-0 mt-10"
      >
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Our Vision
        </h2>
        <p className="text-base md:text-lg lg:text-xl mb-10 text-justify">
          We envision a world where technology seamlessly integrates into
          everyday life, simplifying tasks and enhancing experiences. By
          continuously pushing the boundaries of what's possible, we aim to be
          at the forefront of this digital revolution.
        </p>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Our Mission
        </h2>
        <p className="text-base md:text-lg lg:text-xl mb-10 text-justify">
          Our mission is to transform ideas into reality through innovative
          software products that drive efficiency, productivity, and growth. We
          are dedicated to providing top-notch solutions that meet the evolving
          needs of our clients, ensuring they stay ahead in a rapidly changing
          technological landscape.
        </p>
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 mt-20 text-center">
          Our Leadership Quotes
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-10">
          {/* Founder Card */}
          <div
            style={{ backgroundColor: "#1B1A55", color: "#f0f0f0" }}
            className="shadow-lg rounded-lg p-6"
          >
            <p className="text-base md:text-lg lg:text-xl italic mb-4">
              "Innovation distinguishes between a leader and a follower."
            </p>
            <div className="text-right">
              <h3 className="text-base md:text-lg lg:text-xl font-semibold">
                Ruma Ghosh
              </h3>
              <p className="text-base md:text-lg lg:text-xl text-gray-600">
                Director
              </p>
            </div>
          </div>
          {/* Director Card */}
          <div
            style={{ backgroundColor: "#1B1A55", color: "#f0f0f0" }}
            className="shadow-lg rounded-lg p-6"
          >
            <p className="text-base md:text-lg lg:text-xl italic mb-4">
              "Success is not the key to happiness. Happiness is the key to
              success."
            </p>
            <div className="text-right">
              <h3 className="text-base md:text-lg lg:text-xl font-semibold">
                Tamalika Ghosh
              </h3>
              <p className="text-base md:text-lg lg:text-xl text-gray-600">
                Executive Director
              </p>
            </div>
          </div>
        </div>
        <div className="max-w-screen-xl mx-auto px-4 mt-20">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 mt-20 text-center">
            Meet Our Team
          </h2>
          
          {/* Layout for Medium screens */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:hidden gap-8 mt-10">
            {teamMembers.slice(0, 2).map((member, index) => (
              <div key={index} className="col-span-1">
                <TeamMemberCard
                  name={member.name}
                  position={member.position}
                  imageUrl={member.imageUrl}
                />
              </div>
            ))}
          </div>

          {/* Medium screen second row (2 cards) */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:hidden gap-8 mt-10">
            {teamMembers.slice(2, 4).map((member, index) => (
              <div key={index} className="col-span-1">
                <TeamMemberCard
                  name={member.name}
                  position={member.position}
                  imageUrl={member.imageUrl}
                />
              </div>
            ))}
          </div>

          {/* Medium screen third row (1 centered card) */}
          <div className="grid grid-cols-1 md:grid-cols-1 lg:hidden gap-8 mt-10 justify-center">
            {teamMembers.slice(4, 5).map((member, index) => (
              <div
                key={index}
                className="col-span-1 md:col-span-1 md:justify-self-center"
              >
                <TeamMemberCard
                  name={member.name}
                  position={member.position}
                  imageUrl={member.imageUrl}
                />
              </div>
            ))}
          </div>

          {/* Layout for Large screens */}
          <div className="hidden lg:grid grid-cols-1 lg:grid-cols-3 gap-8 mt-10">
            {/* Large screen first row (3 cards) */}
            {teamMembers.slice(0, 3).map((member, index) => (
              <div key={index} className="col-span-1">
                <TeamMemberCard
                  name={member.name}
                  position={member.position}
                  imageUrl={member.imageUrl}
                />
              </div>
            ))}
          </div>

          {/* Large screen second row (2 cards) */}
          <div className="hidden lg:grid grid-cols-1 lg:grid-cols-2 gap-8 mt-10">
            {teamMembers.slice(3, 5).map((member, index) => (
              <div key={index} className="col-span-1">
                <TeamMemberCard
                  name={member.name}
                  position={member.position}
                  imageUrl={member.imageUrl}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
