import React from "react";
import youtube from "../assets/YouTube.png";
import facebook from "../assets/Facebook.png";
import linkdin from "../assets/Linkdin.png";
import callIcon from "../assets/Mobile.png";
import emailIcon from "../assets/email.png";
import locationIcon from "../assets/location.png";

const Footer = () => {
  return (
    <>
    <footer className="bg-gray-800 text-white py-10 p-4">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-center md:text-left mb-24">
        <div className="max-w-md mx-auto p-4">
          <h3 className="font-bold mb-4 text-lg sm:text-xl md:text-2xl">
            Contact In
          </h3>
          <div className="space-y-3">
            <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal">
              Phone Number:
            </p>
            <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal text-gray-400">
              <a
                href="tel:+918918080399"
                className=" transform transition-transform duration-200"
                >
                <img
                  src={callIcon}
                  alt="Call Icon"
                  className="inline-block w-8 h-8 m-1 transform hover:scale-110 active:scale-90 transition-transform duration-200"
                />
              </a>
              +91 8918080399
            </p>
            <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal">
              Email Address:
            </p>
            <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal text-gray-400">
              <a
                href="mailto:apexiumtechnologies@gmail.com"
                className="transform transition-transform duration-200"
                >
                <img
                  src={emailIcon}
                  alt="Email Icon"
                  className="inline-block w-8 h-8 m-1 transform hover:scale-110 active:scale-90 transition-transform duration-200"
                  />
              </a>
              apexiumtechnologies@gmail.com
            </p>
          </div>
        </div>
        <div>
          <h3 className="font-bold mb-4 text-lg sm:text-xl md:text-2xl">
            Where we are
          </h3>
          <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal">
          <a
                href="https://maps.app.goo.gl/tkbEQYPGnerykHfR8"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 transform transition-transform duration-200"
              >
                <img
                  src={locationIcon}
                  alt="Address Icon"
                  className="w-8 h-8 transform hover:scale-110 active:scale-90 transition-transform duration-200"
                />
            <span>Address:</span>
          </a>
          </p>
          <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal text-gray-400 text-justify">
          {/* <a
                href="https://maps.app.goo.gl/tkbEQYPGnerykHfR8"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center space-x-2 transform transition-transform duration-200"
              >
                <img
                  src={locationIcon}
                  alt="Address Icon"
                  className="w-8 h-8 transform hover:scale-110 active:scale-90 transition-transform duration-200"
                />
                <span> 1ST FLOOR BE 125, AA-1, ST.NO.-163&174, New Town, Kolkata, West Bengal, Pin-700156.</span>
              </a> */}
            1ST FLOOR BE 125, AA-1, ST.NO.-163&174, New Town, Kolkata, West Bengal, Pin-700156.
          </p>
          <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3683.9393517362187!2d88.46856207475739!3d22.581371532649854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a027500758a8955%3A0xdf27b61f3189d55f!2sApexium%20Technologies%20Private%20Limited!5e0!3m2!1sen!2sin!4v1727783316967!5m2!1sen!2sin"
              title ="Apexium Location"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="w-4/5 h-3/5"
            ></iframe>
          {/* <p className="text-base sm:text-lg md:text-lg lg:text-xl font-normal text-gray-400">
            New Town, Kolkata, West Bengal, Pin-700156.
          </p> */}
        </div>
        <div className="pl-0 md:pl-12">
          <h3 className="font-bold mb-4 text-lg sm:text-xl md:text-2xl">
            Follow us{" "}
          </h3>
          <div className="flex justify-center md:justify-start space-x-8 ">
            <a
              href="https://youtube.com/@puphub24"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
              >
              <img src={youtube} alt="YouTube" className="w-10 h-10" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61560652664233"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
              >
              <img src={facebook} alt="Facebook" className="w-10 h-10" />
            </a>
            <a
              href="https://www.linkedin.com/company/103064496/admin/dashboard/"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:opacity-80 transition-opacity"
              >
              <img src={linkdin} alt="LinkedIn" className="w-10 h-10" />
            </a>
          </div>
        </div>
      </div>
      <div className="text-center mt-8">
        <p className="text-gray-500">
          © 2024 Apexium Technologies - All Rights Reserved.
        </p>
      </div>
    </footer>
    </>
  );
};

export default Footer;
