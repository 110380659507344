import React from "react";
import Navbar from "./Navbar.jsx";
import code from "../assets/WebIcon.png";
import code1 from "../assets/MobileIcon-re.png";
import cloud from "../assets/cloud.png";
import designIcon from "../assets/designIcon.png";
import community from "../assets/followers.png";
import star from "../assets/star.png";
import setting from "../assets/settings.png";
import support from "../assets/support.png";
import Consulting from "../assets/communication-r.png";
import '../styles/style.css';

const ServiceCard = ({ imageUrl, title, description }) => {
  
  return (
    <>
    <div className="group relative overflow-hidden  shadow-lg gradient-border transform transition-transform duration-300 hover:scale-105">
      <div className="flex flex-col items-center justify-center w-full h-56 bg-violet-800">
        <img
          src={imageUrl}
          alt={title}
          className="w-40 h-40 object-contain transition-transform duration-300 group-hover:scale-110"
          />
        <p className="text-white text-lg font-bold ">{title}</p>
      </div>
      <div
        style={{ backgroundColor: "#1B1A55", color: "#f0f0f0" }}
        className="absolute inset-0 bg-opacity-60 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
        >
        <div className="text-center text-white p-4">
          <h3 className="text-lg font-semibold mb-2">{title}</h3>
          <p className="text-sm text-justify">{description}</p>
        </div>
      </div>
    </div>
    </>
  );
};

const Service = () => {
  const services = [
    {
      icon: code,
      title: "Website Development",
      description:
        "Your online presence, perfected. Apexium crafts visually stunning and highly functional websites that reflect your brand's identity. Our team blends creativity with the latest web technologies to deliver sites that engage and convert.",
    },
    {
      icon: code1,
      title: "Application Development",
      description:
        "At Apexium, we specialize in building custom applications that meet your unique needs. From initial concept to deployment, we ensure seamless user experiences and cutting-edge technology.",
    },
    {
      icon: cloud,
      title: "Cloud Integration",
      description:
        "Seamlessly connect your business to the cloud. Apexium offers robust cloud integration services that enhance scalability, security, and performance. We partner with industry leaders like AWS to ensure your business is always online and thriving.",
    },
    {
      icon: designIcon,
      title: "UI/UX Design",
      description: "Enhance the user experience and visual appeal of your clients' websites, applications, and other digital products. Focus on creating intuitive interfaces that are easy to navigate and visually appealing.",
    },
    
    {
      icon: star,
      title: "Customization & Integration",
      description:
        "At Apexium, we specialize in building custom applications that meet your unique needs. From initial concept to deployment, we ensure seamless user experiences and cutting-edge technology.",
    },
    {
      icon: Consulting,
      title: "Strategic Consulting",
      description:
        "Implementation Consulting: Leverage our expertise to implement our products effectively, ensuring maximum impact. Business Consulting: Align our products with your strategic goals, driving growth and efficiency in your operations.",
    },
    {
      icon: support,
      title: "Product Support",
      description:
        "Technical Support: Ensure your product operates flawlessly with our expert troubleshooting and problem-solving services. Customer Service: Get timely assistance with inquiries, product guidance, and order support.",
    },
    {
      icon: setting,
      title: "Product Evolution",
      description:
        "Software Updates: Stay ahead with regular updates that introduce new features, enhance security, and improve performance. Maintenance Services: Keep your products optimized with ongoing maintenance, including bug fixes and system upgrades.",
    },
    {
      icon: community,
      title: "Knowledge & Community",
      description:
        "Community Forums: Engage with a network of users to share experiences, solutions, and insights. Knowledge Base: Access a wealth of resources, including articles, FAQs, and guides, to maximize your product's potential.",
    },
  ];

  return (
    <>
      <Navbar />
      <div
        style={{ backgroundColor: "#070F2B", color: "#f0f0f0" }}
        className="relative z-10 p-8 md:p-12 lg:p-16 mb-0 mt-10"
      >
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4">
          Our Services
        </h2>
        <p className="text-base md:text-lg lg:text-xl mb-10">
        At Apexium Technology Private Limited, our commitment to innovation drives us to deliver cutting-edge products and the services that ensure they perform at their best. Here’s how we support your business:
        </p>
        <div className="max-w-screen-xl mx-auto px-4 mt-20">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <ServiceCard
                key={index}
                imageUrl={service.icon}
                title={service.title}
                description={service.description}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Service;
