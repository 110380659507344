import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar.jsx";
import Footer from "./Footer.jsx";
import HomeVideo from "../assets/Video/Home-Video.mp4";
import thumbnail from "../assets/Thumbnail.png"

const ContactUs = () => {
  // State for form data
  const [name, setName] = useState("");
  const [mobile_num, setMobileNum] = useState("");
  const [email_id, setEmailId] = useState("");
  const [message, setMessage] = useState("");

  const url = process.env.REACT_APP_API_URL;
  console.log('API URL:', url);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // Construct the data object to send
    const data = {
      name,
      mobile_num,
      email_id,
      message,
    };
    
    // const url = process.env.REACT_APP_API_URL;
    // console.log('API URL:', url);
    // const url = "http://localhost:9015";
    try {
      const response = await fetch(`${url}/customer-details`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      console.log(`ok2`);
      if (response.ok) {
        // Reset form fields on success
        setName("");
        setMobileNum("");
        setEmailId("");
        setMessage("");
        toast.success("Message sent successfully!");
      } 
      else if (!name || !mobile_num || !email_id || !message) {
          toast.error("Please fill in all required fields.");
          return; 
      }
      else {
        toast.info("Your message has been received. We'll be in touch shortly.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <div>
        <div className="relative w-full min-h-screen overflow-hidden">
          <Navbar />
          <video
            autoPlay
            loop
            muted
            playsInline
            poster= {thumbnail}
            className="absolute top-0 left-0 w-full h-full object-cover"
            onError={(e) => {
              console.error("Error loading video:", e);
            }}
          >
            <source src={HomeVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="relative z-10 flex flex-col items-center justify-center h-full text-white px-8 lg:px-16 mt-16">
            <div className="flex flex-col lg:flex-row lg:space-x-8">
              <div className="text-center lg:text-left lg:w-1/2 mb-12 lg:mb-0 mt-20">
                <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
                  Get in Touch with Us
                </h1>
                <p className="text-lg md:text-xl lg:text-2xl">
                  We're here to help you. Reach out to us for any inquiries,
                  project discussions, or support needs.
                </p>
              </div>
              <form
                onSubmit={handleSubmit}
                className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg bg-white text-black p-4 sm:p-6 md:p-8 lg:p-10 rounded-lg shadow-lg mt-10 mb-5 flex flex-col"
              >
                <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-2xl font-bold mb-4 sm:mb-6 text-center">
                  Contact Form
                </h2>
                <div className="mb-3 sm:mb-4">
                  <label
                    htmlFor="name"
                    className="block text-xs sm:text-sm font-medium mb-1 sm:mb-2"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md"
                    placeholder="Your Name"
                  />
                </div>
                <div className="mb-3 sm:mb-4">
                  <label
                    htmlFor="mobile"
                    className="block text-xs sm:text-sm font-medium mb-1 sm:mb-2"
                  >
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    id="mobile"
                    name="mobile"
                    value={mobile_num}
                    onChange={(e) => setMobileNum(e.target.value)}
                    required
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md"
                    placeholder="Enter your mobile number"
                    pattern="[0-9]{10}"
                  />
                </div>
                <div className="mb-3 sm:mb-4">
                  <label
                    htmlFor="email"
                    className="block text-xs sm:text-sm font-medium mb-1 sm:mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email_id}
                    onChange={(e) => setEmailId(e.target.value)}
                    required
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md"
                    placeholder="Your Email"
                  />
                </div>
                <div className="mb-3 sm:mb-4">
                  <label
                    htmlFor="message"
                    className="block text-xs sm:text-sm font-medium mb-1 sm:mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows="3"
                    required
                    className="w-full p-2 sm:p-3 border border-gray-300 rounded-md"
                    placeholder="Your Message"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="bg-black text-white px-4 sm:px-5 py-2 sm:py-3 rounded-md font-bold hover:bg-gray-800 transition-colors"
                >
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
        <Footer />
        <ToastContainer />
      </div>
    </>
  );
};

export default ContactUs;
