import React from "react";
import Navbar from "../components/Navbar.jsx";
import appIcon from "../assets/PupHub.png";
import HomeVideo from "../assets/Video/Home-Video.mp4";
import thumbnail from "../assets/Thumbnail.png"

const Product = () => {
  return (
    <>
      <div className="relative w-full h-screen overflow-hidden">
        <Navbar />
        <video
          autoPlay
          loop
          muted
          playsInline
          poster= {thumbnail}
          className="absolute top-0 left-0 w-full h-full object-cover"
          onError={(e) => {
            console.error("Error loading video:", e);
          }}
        >
          <source src={HomeVideo} type="video/mp4" />
        </video>
        <div className="relative z-10 flex flex-col items-center justify-center h-full px-8 lg:px-16">
          <div>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-10 text-center  text-white">
              Explore Our Products
            </h1>
          </div>
          <div className="bg-white shadow-lg rounded-lg max-w-3xl w-full">
            <div className="flex flex-col items-center p-2">
              <h3 className="text-xl md:text-2xl lg:text-3xl  font-bold text-center  text-black mt-5">
                PupHub
              </h3>
            </div>
            <div className="flex flex-col md:flex-row items-center md:justify-between p-6">
              <div className="flex flex-col md:flex-row items-center space-x-4">
                <img
                  src={appIcon}
                  alt="PupHub"
                  className="w-17 h-17  mb-4 md:mb-0"
                />
                <div>
                  <p className="text-black mb-4 text-base md:text-lg lg:text-xl text-left">
                  To learn more about PupHub and explore its features, click the buttons to visit PupHub website or directly download PupHub app from the Play Store.
                  </p>
                  <div className="mt-4 md:mt-0 flex flex-col items-center md:flex-row md:justify-end w-full">
                    <a
                      href="https://www.puphub.co.in/" // Replace with the actual PupHub website URL
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mb-4 md:mb-0 md:mr-4"
                    >
                      <button className="bg-black text-white px-4 py-3 rounded-md text-lg font-bold hover:bg-gray-600">
                        Visit Website
                      </button>
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.puphub"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button className="bg-black text-white px-4 py-3 rounded-md text-lg font-bold hover:bg-gray-600">
                        Download
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
