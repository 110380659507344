import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <nav className="bg-gray-800 text-white shadow-md fixed top-0 w-full h-18 z-20">
      <div className="max-w-screen-xl mx-auto px-4 py-3 flex items-center justify-between">
        {/* Brand */}
        <div className="flex items-center">
          <Link to="/" className="text-3xl font-bold brand-name">APEXIUM</Link>
        </div>

        {/* Hamburger Menu Button */}
        <button
          onClick={toggleMenu}
          className="text-white md:hidden"
          aria-label="Toggle Menu"
          >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
              />
          </svg>
        </button>

        {/* Desktop Menu */}
        <div className="hidden md:flex items-center space-x-6 text-lg">
          <Link to="/" className="hover:text-gray-300">Home</Link>
          <Link to="/about" className="hover:text-gray-300">About Us</Link>
          <Link to="/service" className="hover:text-gray-300">Our Services</Link>
          <Link to="/product" className="hover:text-gray-300">Our Products</Link>
          <Link to="/contact" className="hover:text-gray-300">Contact Us</Link>
        </div>

        {/* Mobile Menu */}
        <div
          className={`md:hidden absolute top-16 right-0 w-full bg-gray-800 transition-all duration-300 ease-in-out ${isOpen ? 'block' : 'hidden'}`}
          style={{ maxWidth: '200px' }}  // Adjust width for mobile menu
          >
          <div className="flex flex-col">
            <Link to="/" className="block px-4 py-2 hover:text-gray-300 border-b border-gray-600">Home</Link>
            <Link to="/about" className="block px-4 py-2 hover:text-gray-300 border-b border-gray-600">About Us</Link>
            <Link to="/service" className="block px-4 py-2 hover:text-gray-300 border-b border-gray-600">Our Services</Link>
            <Link to="/product" className="block px-4 py-2 hover:text-gray-300 border-b border-gray-600">Our Products</Link>
            <Link to="/contact" className="block px-4 py-2 hover:text-gray-300 border-b border-gray-600">Contact Us</Link>
          </div>
        </div>
      </div>
    </nav>
    </>
  );
};

export default Navbar;
