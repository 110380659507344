import React, { useState, useEffect } from "react";
import HomeVideo from "../assets/Video/Home-Video.mp4";
import thumbnail from "../assets/Thumbnail.png"
import Navbar from "./Navbar.jsx";
import client1 from "../assets/client1.png";
import client2 from "../assets/client2.png";
import client3 from "../assets/client3.png";

const reviews = [
  {
    id: 1,
    name: "Alice Brown",
    role: "CTO, Tech Innovators Inc.",
    message:
      "Apexium's solutions transformed our infrastructure. Their expertise is unparalleled.",
    image: client1, // replace with actual image paths
  },
  {
    id: 2,
    name: "Michael Lee",
    role: "Lead Developer, WebWorks",
    message:
      "The team at Apexium provided top-notch service, delivering our project on time and beyond expectations.",
    image: client2,
  },
  {
    id: 3,
    name: "Sarah Johnson",
    role: "Product Manager, CloudSync",
    message:
      "Working with Apexium has been a game-changer for our product development process.",
    image: client3,
  },
];

const Home = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 5000); // Change card every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  const currentReview = reviews[currentReviewIndex];

  return (
    <>
      <div className="relative w-full h-[72vh] overflow-hidden">
        <Navbar />
        <video
          autoPlay
          loop
          muted
          playsInline
          poster= {thumbnail}
          className="absolute top-0 left-0 w-full h-[72vh] object-cover "
          onError={(e) => {
            console.error("Error loading video:", e);
          }}
        >
          <source src={HomeVideo} type="video/mp4" />
        </video>
        <div className="relative z-10 flex items-center justify-center h-full text-white px-8 lg:px-16">
          <div>
            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">
              Welcome to Apexium Technology
            </h1>
            <p className="text-lg md:text-xl lg:text-2xl text-right">
              Empowering Tech Solutions
            </p>
          </div>
        </div>
      </div>

      <div
        style={{ backgroundColor: "#070F2B", color: "#f0f0f0" }}
        className="relative z-10 p-8 md:p-12 lg:p-16"
      >
        <div className="container mx-auto">
          {/* Overview Section */}
          <div className="mb-16">
            <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-left">
              Overview
            </h2>
            <p className="text-base md:text-lg lg:text-xl mb-10 text-justify">
            Welcome to Apexium, where innovation meets excellence. As a product-based IT company, we are dedicated to delivering top-quality solutions that enhance your everyday life. Founded on April 19th, 2024, our commitment to craftsmanship, cutting-edge technology, and customer satisfaction drives us to create products that not only meet but exceed your expectations. Step into a world of unparalleled quality, reliability, and unmatched service with Apexium.
            </p>
          </div>
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-4 text-center">
            People Reactions
          </h2>

          {/* Customer Review Section */}
          <div className="flex flex-col md:flex-row justify-center items-center mt-10">
            <div
              style={{ backgroundColor: "#1B1A55", color: "#f0f0f0" }}
              className="shadow-lg rounded-lg p-6 flex flex-col md:flex-row items-center w-full max-w-3xl"
            >
              {/* Left Side: Rounded Photo */}
              <div className="mb-4 md:mb-0 md:mr-6 flex-shrink-0">
                <img
                  src={currentReview.image}
                  alt={currentReview.name}
                  className="w-28 h-28 rounded-full object-cover"
                />
              </div>
              {/* Right Side: Message */}
              <div className="flex-1 text-center md:text-left">
                <p className="text-base md:text-lg lg:text-xl italic mb-4">
                  "{currentReview.message}"
                </p>
                <div>
                  <h3 className="text-base md:text-lg lg:text-xl font-semibold text-right">
                    {currentReview.name}
                  </h3>
                  <p className="text-sm md:text-base text-gray-400 text-right">
                    {currentReview.role}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
